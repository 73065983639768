<template>
  <div class="border-b border-gray-200 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px">
      <li class="mr-2" v-for="tab in tabs" :key="tab.name">
        <router-link
          class="inline-block py-4 px-4 text-sm font-medium text-center rounded-t-lg border-b-2"
          :to="tab.to"
          :class="{
            'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300':
              !tab.current,

            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500':
              tab.current,
          }"
        >
          {{ tab.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: "Informatii generale",
          current: false,
          to: {
            name: "vendors.show",
            params: { id: this.id },
          },
        },
        {
          name: "Comisioane",
          current: false,
          to: {
            name: "vendors.fees.index",
            params: { id: this.id },
          },
        },
        {
          name: "Retrageri",
          current: false,
          to: {
            name: "vendors.withdrawals.index",
            params: { id: this.id },
          },
        },
        {
          name: "Comisioane aplicate",
          current: false,
          to: {
            name: "vendors.charges.index",
            params: { id: this.id },
          },
        },
      ],
    };
  },

  watch: {
    current(newValue) {
      this.updateCurrentTab(newValue);
    },
  },

  methods: {
    updateCurrentTab(to) {
      this.tabs = this.tabs.map((tab) => {
        return {
          ...tab,
          current: `${to}` === tab.to.name,
        };
      });
    },
  },

  created() {
    this.updateCurrentTab(this.current);
  },
};
</script>

<style></style>
