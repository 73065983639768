import { VENDORS_CHARGES } from "../endpoints";
import api from "../api";

export default {
    index(vendor = null, query = null) {
        if (!vendor)
            return;

        const url = VENDORS_CHARGES.replace(":vendor", vendor);

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },
}