<template>
  <app-layout>
    <template v-slot:header> Comisioane aplicate</template>
    <page-section>
      <vendors-show-tabs current="vendors.charges.index" :id="id" />
    </page-section>
    <page-section>
      <v-card>
        <loader v-if="loading" class="my-5" />
        <div v-else-if="totalRows">
          <vendors-charges-index-table
            :rows="rows"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </div>
        <no-results v-else class="m-5" />
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import vendorsChargesApi from "@/api/resources/vendorsChargesApi";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Pagination from "../components/Pagination.vue";
import VendorsChargesIndexTable from "../components/VendorsChargesIndexTable.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";
import PageSection from '../components/ui/PageSection.vue';
import VendorsShowTabs from '../components/VendorsShowTabs.vue';

export default {
  components: {
    AppLayout,
    VCard,
    Pagination,
    VendorsChargesIndexTable,
    Loader,
    NoResults,
    PageSection,
    VendorsShowTabs,
  },
  props: ["id"],

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;

      vendorsChargesApi
        .index(this.id, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>
